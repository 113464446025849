export const liveSectionCopy = {
  title: (
    <>
      <span className="font-sora">Freelance Academy</span> شنوة
    </>
  ),
  subtitle: (
    <div className="antialiased leading-[160%]">
      دورات يعلموك Freelance مهما كان مستواك, بش توصل تدخل أول مليون. مع الدورات
      الستة المسجلة بش تاخو برشا محتوى اخر ينجم يعاونك. من ملفات, روابط و حتى
      فيديوهات اضافية. بش تتحصل زادة على اجتماع شهري يجاوبك فيه قصي على
      استفساراتك و حصص آسبوعية مع خبراء آخرين.
    </div>
  ),
};

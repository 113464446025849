

const GreenLinear = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="248"
      height="244"
      viewBox="0 0 248 244"
      fill="none"
    >
      <ellipse
        cx="124.005"
        cy="122.101"
        rx="123.95"
        ry="120.957"
        transform="rotate(27.8868 124.005 122.101)"
        fill="#52CC92"
      />
    </svg>
  );
};

export default GreenLinear;

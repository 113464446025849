

const BlueLinear = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="248"
      height="244"
      viewBox="0 0 248 244"
      fill="none"
    >
      <ellipse
        cx="123.995"
        cy="121.899"
        rx="123.95"
        ry="120.957"
        transform="rotate(27.8868 123.995 121.899)"
        fill="#61CFDD"
      />
    </svg>
  );
};

export default BlueLinear;

import BlueLinear from "src/svg/BlueLinear";
import GreenLinear from "src/svg/GreenLinear";

const LiveLinear = () => {
  return (
    <div className="top-1/2 left-1/2 -z-10 absolute opacity-50 -translate-x-1/2 -translate-y-1/2">
      <div className="rotate-[114.887deg]">
        <div className="blur-[150px] -translate-y-[-50px]">
          <BlueLinear />
        </div>
        <div className="blur-[150px]">
          <GreenLinear />
        </div>
      </div>
    </div>
  );
};

export default LiveLinear;

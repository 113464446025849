import MainTitle from "@components/ui/MainTitle";
import Section from "@components/ui/Section";
import LinearContainer from "@components/ui/linear-container/linear-container";
import { liveSectionCopy } from "src/content/live-section";
import LiveLinear from "./LiveLinear";

const LiveSection = () => {
  const deadline = new Date();
  deadline.setHours(deadline.getHours() + 48);
  return (
    <Section className="py-0 max-w-full">
      <LinearContainer
        noBottomBorder
        className="bg-linear-gradient-w1 px-3 md:px-5 lg:px-8 py-24 overflow-hidden"
      >
        <div className="flex flex-col items-center gap-4 mx-auto w-full max-w-full md:max-w-[60.125rem]">
          <MainTitle
            title={liveSectionCopy.title}
            subTitle={liveSectionCopy.subtitle}
          />
        </div>
        <div className="flex flex-col items-center gap-8 mx-auto pb-0 md:pb-4 lg:pb-8 w-full max-w-full md:max-w-[68.125rem]">
          <div className="relative">
            <LiveLinear />
            <img
              src="/images/live-section/live-graphic.webp"
              alt="live graphic"
              width={1448}
              height={465}
            />
          </div>
        </div>
      </LinearContainer>
    </Section>
  );
};

export default LiveSection;
